<template>
  <div>
    <div id="contact">
      <v-container class="content">
        <h2 class="display-2 font-weight-bold mb-10 primary--text">
          Please do get in touch!
        </h2>
        <v-row>
          <v-col cols="12" md="8">
            <p>
              Find out more about how this might work for your high street or
              town centre.
            </p>
            <p>
              Please call or email us directly or fill out the form:
            </p>
            <form
              name="contact"
              method="post"
              @submit.prevent="handle_submit"
              netlify-honeypot="bot-field"
              netlify
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    name="name"
                    v-model="form.name"
                    :rules="nameRules"
                    label="Name"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    name="email"
                    v-model="form.email"
                    :rules="emailRules"
                    label="Email"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    rounded
                    outlined
                    name="message"
                    v-model="form.message"
                    label="Message"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="form-action">
                <v-btn color="primary" class="px-10 white--text" type="submit"
                  >Send</v-btn
                >
              </div>
            </form>
          </v-col>
          <v-col cols="12" md="4">
            <div
              class="personal-contacts"
              :class="{
                'ml-8': $vuetify.breakpoint.mdAndUp,
                'pl-4': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <!-- <v-list dense>
                <p
                  class="label pa-2 primary white--text"
                  :class="{
                    'ml-n4': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  BIDs contact
                </p>
                <p class="title font-weight-bold mb-2">
                  George Grace
                </p>
                <v-list-item class="pl-0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-at</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >george@wearelocals.co.uk</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>(+44) 780 179 0645</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

              <v-list dense>
                <p
                  class="label pa-2 primary white--text mb-4"
                  :class="{
                    'ml-n4': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  Contact us
                </p>
                <v-list-item class="pl-0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-at</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >hello@wearelocals.co.uk</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>(+44) 117 313 6883</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      loading: false,
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handle_submit() {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": "contact",
          ...this.form,
        }),
      })
        .then(() => {
          this.$router.push("received");
        })
        .catch(() => {
          this.$router.push("404");
        });
    },
  },
};
</script>

<style lang="scss">
.form-action {
  text-align: right;
}

#contact {
  padding: 20px 0;
  position: relative;
}

.personal-contacts {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .label {
      border-radius: 8px;
    }
  }
}
</style>
